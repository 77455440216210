<template>
  <div>
    <div class="height-45 s_flex_bian_c width100">
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 1 ? "s_bf054 tw-text-white":"s_bf"'
        @click='active = 1'>商品信息</div>
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 2 ? "s_bf054 tw-text-white":"s_bf"'
        @click='active = 2'>物流码信息</div>
    </div>
    <div class="m-t-10" v-if='active == 1'>
      <van-field v-model="form.out_warehousing_sn" name="out_warehousing_sn" label="出库单号：" readonly />
      <van-field v-model="form.to" name="to" label="收货人：" readonly />
      <van-field v-model="form.warehouse_position_name" name="warehouse_position_name" label="出库仓库：" readonly />
      <van-field v-model="form.out_warehousing_status_text" name="out_warehousing_status_text" label="状态：" readonly />
      <van-field class="m-t-10" label="商品信息" readonly />
      <div class="tw-bg-white p-l-15 p-r-15 m-b-10" v-for="(v,k) in form.product" :key="k">
        <van-card class="tw-bg-white" :thumb="info.oss_domain+v.product_image">
          <template #title>
            <p class="fz-15 s_omit">{{v.product_name}}</p>
          </template>
          <template #desc>
            <p class="tw-text-gray-400 s_lin_h22">{{v.product_sku_name || '单规格'}}</p>
            <p class="tw-text-gray-400 s_lin_h22">商品编号：{{v.product_sn}}</p>
          </template>
        </van-card>
        <van-divider :style="{ margin: '0' }"/>
        <van-field style="padding-left: 0;padding-right: 0;" v-model="v.out_warehousing_num" name="out_warehousing_num" label="出库数量：" readonly />
      </div>
    </div>
    <div class="m-t-10" v-if='active == 2'>
      <van-search v-model="code_sn" show-action placeholder="请输入物流码进行搜索" @search="getCode">
        <template #action>
          <div class="height-34 s_flex_center s_bf054 width-55 tw-text-white tw-rounded-sm" @click="getCode">搜索</div>
        </template>
      </van-search>
      <div class="p-15 s_bf">
        <div class="tw-border tw-border-gray-200 tw-border-solid tw-border-b-0">
          <div
            class="s_flex_bian_c s_bf5 height-40 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-gray-200 tw-border-solid">
            <p class="width33 s_flex_center">物流码</p>
            <p class="width33 s_flex_center">码类型</p>
            <p class="width33 s_flex_center">商品数量</p>
          </div>
          <div
            class="s_flex_bian_c height-40 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-gray-200 tw-border-solid"
            v-for="(item,index) in codeList" :key='index'>
            <p class="tw-text-center width33">{{item.code_sn}}</p>
            <p class="tw-text-center width33">{{item.code_type_text}}</p>
            <p class="tw-text-center width33">{{item.code_num}}</p>
          </div>
        </div>
      </div>
      <van-empty v-if="!codeList.length" :image="require('@/assets/image/empty.png')" description="暂无物流码" />
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs, computed } from "vue";
  import { getOutWarehouseMore, getOutWarehouseCode } from '@/api/outWarehouse'
  import { useRoute } from 'vue-router';
  import { iscode } from '@/utils/app'
  import store from '@/store'
  export default {
    setup() {
      const form = ref({});
      const info = computed({
        get() {
          return store.state.user.info
        }
      })
      const state = reactive({
        active: 1,
        list: [{}, {}, {}],
      });
      const route = useRoute()
      const initData = async () => {
        // 详情
        let result = await getOutWarehouseMore(route.query.out_warehousing_sn).then(res => res.data)
        if (iscode(result)) {
          form.value = result.data
        } else {
          iscode(result, true)
        }

      }
      const codeList = ref([])
      const code_sn = ref("")
      const getCode = async () => {
        // 物流码信息
        let codeResult = await getOutWarehouseCode({ out_warehousing_sn: route.query.out_warehousing_sn, sn: route.query.out_warehousing_sn, code_sn: code_sn.value }).then(res => res.data).catch(error => error)
        codeList.value = codeResult.data
      }
      onMounted(() => {
        initData()
        getCode()
      });

      return {
        ...toRefs(state),
        form,
        info,
        codeList,
        getCode,
        code_sn
      };
    },
  };
</script>
